<template>
  <v-card
    outlined
    :to="{ name: 'Team', params: { id: team.id }}"
    class="mb-4"
  >
    <v-img
      :src="team.image"
      height="200px"
      class="px-3"
    >
      <v-row class="mb-4">
        <v-col>
          <div class="headline">
            {{ team.name }}
          </div>
          <div class="title">
            {{ team.number_of_members }} members
          </div>
        </v-col>
      </v-row>
      <v-row class="align-end pt-5">
        <v-col>
          <div class="title">
            {{ team.quests_completed }}
          </div>
          <div>
            Quests Completed
          </div>
        </v-col>
        <v-col class="text-right">
          <div class="title">
            {{ team.points_earned }}
          </div>
          <div>
            Points
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    team: {
      type: Object,
      required: true,
    }
  },
}
</script>

<template>
  <div class="teams">
    <v-row v-if="canCreateTeam">
      <v-col>
        <v-btn :to="{name: 'TeamCreate'}">
          Create a Team
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        Teams
      </v-card-title>
      <v-card-actions v-if="!loading">
        <v-btn @click.stop="filterDialog = true">
          <v-icon>
            $filter
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <Loading
          v-if="loading"
          :loading="loading"
          message="Loading Teams"
        />
        <div v-else-if="teamList.length">
          Showing {{ teamList.length }} teams
          <v-row>
            <v-col
              v-for="team in teamList"
              :key="team.id"
              cols="12"
              md="6"
              xl="4"
            >
              <v-sheet min-height="200">
                <v-lazy
                  v-model="team.isActive"
                  :options="{ threshold: .2 }"
                >
                  <TeamTile :team="team" />
                </v-lazy>
              </v-sheet>
            </v-col>
          </v-row>
          <v-btn
            v-if="nextPage"
            @click="loadNextPage"
          >
            load more
          </v-btn>
        </div>
        <v-alert
          v-else-if="errored"
          type="error"
        >
          Failed to fetch teams.
        </v-alert>
        <div v-else>
          No teams found.
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="filterDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="$vuetify.breakpoint.thresholds.sm"
    >
      <v-card>
        <v-toolbar>
          <v-row align="center">
            <v-col>
              <v-btn
                icon
                @click="filterDialog = false"
              >
                <v-icon>
                  $close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center">
              <v-toolbar-title>
                Filter
              </v-toolbar-title>
            </v-col>
            <v-col class="text-right">
              <v-btn
                text
                color="primary"
                @click="resetFilterSelections"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-form
          ref="form"
          @submit.prevent="submitFilters"
        >
          <v-card-text>
            <v-text-field
              v-model="filterName"
              label="Name"
              type="text"
              clearable
            />
            <v-select
              v-model="filterRegion"
              :items="regionList"
              item-text="name"
              item-value="id"
              label="Last Active in Region"
              clearable
            />
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              type="submit"
            >
              Show Filter Results
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import TeamTile from '@/components/TeamTile.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    TeamTile,
    Loading,
  },
  data: function() {
    return {
      filterDialog: false,
      filterErrored: false,
      filterLoading: false,
      filterName: null,
      filterRegion: null,
      teamList: [],
    }
  },
  computed: {
    ...mapState({
      allTeams: state => state.teams.all,
      nextPage: state => state.teams.next,
      profile: state => state.auth.profile,
      regionList: state => state.regions.all,
      teamErrored: state => state.teams.errored,
      teamLoading: state => state.teams.loading,
    }),
    canCreateTeam() {
      return this.profile.can_create_teams
    },
    filterQuerystring() {
      let querystring = ''
      if (this.filterRegion) {
        querystring += `last_active_region=${this.filterRegion}&`
      }
      if (this.filterName) {
        querystring += `name=${this.filterName}&`
      }
      if (querystring) {
        querystring = `?${querystring}`
      }
      return querystring
    },
    loading() {
      return this.filterLoading || this.teamLoading
    },
    errored() {
      return this.filterErrored || this.teamErrored
    },
  },
  created: function () {
    this.$store.dispatch('teams/getAll').finally(() => this.teamList = this.allTeams)
  },
  methods: {
    loadNextPage () {
      this.$store.dispatch('teams/loadNextPage').finally(() => this.teamList = this.allTeams)
    },
    resetFilterSelections() {
      this.filterRegion = null
      this.filterName = null
    },
    submitFilters() {
      this.filterDialog = false
      if (!this.filterQuerystring) {
        this.teamList = this.allTeams
        return this.teamList
      }
      this.filterLoading = true
      return allianceAPI.get(`/quest/team/${this.filterQuerystring}`)
        .then((response) => this.teamList = response.data.results)
        .catch(() => this.filterErrored = true)
        .finally(() => this.filterLoading = false)
    },
  },
}
</script>
